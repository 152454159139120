@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@300&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import "/src/style/reset.scss";

.app-header{
    padding: .5rem 0;
    
    @media screen and (max-width: 576px) { 
        text-align: center;
     }
}
.app-header .nav .nav-link{
    color: #191919;
    
    &:hover{
        text-decoration: underline;
    }
    @media (max-width: 575.98px) {
        display: none;
     }
}
