@import "./reset.scss";

//Colors
$teal-green-dark: #075e54;
$teal-green-light: #128c7e;
$light-green: #25d366;
$light-green-octa: #DDF5E7;
$solid-green-octa: #1BBB60;

body {
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  font-family: "Poppins", sans-serif;
}

.titulo-pagina {
  font-size: 2.2em;
  padding: 1rem 0;
  font-weight: 700;

  @media (max-width: 575.98px) { 
    text-align: center;
   }
}
.titulo-pagina, .item-passo-a-passo{
  color: #191919;
}

p, textarea, input{
  font-size: 14px;
}

ol {
  list-style: inside decimal;
  padding-left: 0;
}

main.page-home {
  min-height: 100%;
  height: 800px;
  background-color: #DDF5E7;
  padding-top: 1rem;

  @media (max-width: 575.98px) {
    height: 100%;
    /*display: grid;
    grid-template-columns: 1fr 1fr;*/
  }
}

.initial{
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column; 
  padding: 7rem 32px;

  /*@media screen and (max-width: 1024px) {
    min-height: 100vh;
  }*/
}
.initial .logo-octa{
  padding: 1rem 0;
}
.initial .passo-a-passo{
  padding: 0;
}
.generate-link{
  display: flex;
  align-items: center;
  justify-content: left; 
} 

.generate-link {
  @media screen and (max-width: 1024px) {
    padding: 1rem 0;
  }
}

.page-name{
  padding: 0 5rem 0 3rem;
  position: relative;  
  
  @media (max-width: 575.98px) {
    padding: .5rem;
  }

  &::before{
    content: url(../img/seta.png);
    position: absolute;
    right: -9rem;
    top: -8rem;
    
    @media (max-width: 575.98px) {
      content: "";
    }
    @media (min-width: 1400px) { 
      content: url(../img/seta.png);
      position: absolute;
      right: -9rem;
      top: -10rem;
    }
  }
}
.page-name ol li {
  margin-top: 16px;
}

.form-container {
  max-width: 450px;
  width: 100%;
  height: auto;
  padding: 60px 40px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 2rem;

  -webkit-box-shadow: 2px 5px 15px -2px rgba(0,0,0,0.23); 
  box-shadow: 2px 5px 15px -2px rgba(0,0,0,0.23);

  @media screen and (max-width: 1024px) {
    margin: 0 16px;
  }
}
.copy-link-form {
  display: block;
}

label {
  font-weight: 400;
  display: block;
  margin-bottom: 16px;
  color: #4C515F;
}
.PhoneInputCountry{
  background-color: #fafafa;
  padding: 1rem 0.5rem;
}
.input, .PhoneInputInput,
textarea,
.display-link {
  background: #fafafa;
  width: 100%;
  border-radius: 10px;
  outline: none;
  padding: 12px;
  min-height: 32px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #C8CBD2;
  transition: all 0.5s ease-out;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $light-green;
  }
}

.error .PhoneInputInput {
  position: relative;
  border: 2px solid #cc0000 !important;
}
textarea {
  resize: none;
  height: 120px;
}

.success-message{
  text-align: center;
}
.success-message h2{
  font-size: 1.5em;
  font-weight: 600;
}
.success-message p{
  font-weight: 400;
}
.input-container {
  margin-bottom: 32px;
}

.display-link {
  display: flex;
  justify-content: space-between;
  max-height: 56px;
}

.display-link input {
  width: 90%; 
  outline: none;
  background-color: transparent;
}

.btn-green-1BBB60 {
  width: 100%;
  padding: 12px 16px;
  height: 60px;
  background-color: $solid-green-octa;
  outline: none;
  position: relative;
  border-radius: 0;
  font-weight: 600;
  font-size: 16px;
  color: #fff;

  transition: .2s ease-in-out;

  &:hover{
    color: #fff;
  }
  &::after{
    content: url(../img/border-btn.png);
    position: absolute;
    left: 0;
    top: 0;

      @media (max-width: 575.98px) { 
        content: '';
      }
  }
  &:hover::before{
    top: 1.5rem;
    left: -.5rem;
  }

}

.icon-button {
  background-color: $solid-green-octa;
  position: absolute;
  left: 1rem;
  top: 0;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.btn-copy{
  background-color: transparent;
  outline: none;
  color: $light-green;
}
.alert{
  display: none;
  margin: 0.5rem 0;
}
